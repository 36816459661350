<template>
  <!-- Перевод добавлен -->
  <div class="filter-quick-calculation">
    <div class="filter-quick-calculation__label">
      <span>{{ $t('Quick calculation') }}</span>
      <div
        v-if="$store.getters['other/mobileContent']"
        @click="() => $store.commit('sidebars/changeLeftSidebar', false)"
      >
        <font-ai class="text-primary" icon="fa-solid fa-xmark" />
      </div>
    </div>
    <div class="filter-quick-calculation__bread-crumbs">
      <div>
        <span>{{ breadCrumbsList.slice(0, -1).join(' / ') }}</span>
        <span class="filter-quick-calculation__bread-crumbs__last">
          / {{ breadCrumbsList[breadCrumbsList.length - 1] }}
        </span>
      </div>
    </div>
    <p v-if="showCreateFirstDirectory" class="zem-link" @click="addFirstCategory">
      {{ $t('Create the first category') }}
    </p>
    <template v-if="!showCreateFirstDirectory">
      <!--            <Checkbox-->
      <!--                :value="$store.state.quickCalculation.activeDragAndDrop"-->
      <!--                @input="() => {$store.commit('quickCalculation/updateDragAndDrop')}"-->
      <!--                class="checkbox-dnd"-->
      <!--            >-->
      <!--                Перетаскивание-->
      <!--            </Checkbox>-->
      <!--            <Checkbox-->
      <!--                :value="$store.state.quickCalculation.activeMultipleChoice"-->
      <!--                @input="() => {$store.commit('quickCalculation/updateMultipleChoice')}"-->
      <!--                class="mb-1"-->
      <!--            >Множественный выбор-->
      <!--            </Checkbox>-->
      <TestTree
        :checkboxes="$store.state.quickCalculation.activeMultipleChoice"
        :draggable="$store.state.quickCalculation.activeDragAndDrop"
        :list="elements"
      />
    </template>
  </div>
</template>

<script>
import Checkbox from '@/components/ui/Checkbox'
import ProductsService from '@/services/quickCalculation.service'
import {eventBus} from '@/main'
import TestTree from '@/components/ui/TestTree.vue'

export default {
  components: {
    TestTree,
    Checkbox,
  },

  data() {
    return {
      elements: [],
      showCreateFirstDirectory: undefined,
    }
  },

  computed: {
    breadCrumbsList() {
      return this.$store.state.products.breadCrumbs.reverse()
    },
  },

  mounted() {
    this.getDirectory()
    this.elements = this.$store.state.products.directoriesTree
    eventBus.$on('update-elements-tree', () => {
      if (this.$store.state.products.directoriesTree.length === 0) this.showCreateFirstDirectory = true
      this.elements = this.$store.state.products.directoriesTree
    })
  },

  methods: {
    getDirectory() {
      ProductsService.getDirectory()
    },
    addFirstCategory() {
      this.$store.commit('quickCalculation/updateDirectoriesTree', [
        {
          title: '',
          real_id: 0,
          edit: true,
          new: true,
          children: {
            data: [],
          },
        },
      ])
      this.elements = this.$store.state.quickCalculation.directoriesTree
      this.showCreateFirstDirectory = false
    },
  },
}
</script>

<style lang="scss">
.filter-quick-calculation {
  display: block;
  padding: 32px 24px;
  min-width: 192px;
  overflow: auto;
  height: 100%;

  .checkbox-dnd {
    margin-bottom: 6px;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: $color-mine-shaft;
  }

  &__bread-crumbs {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $color-gull-gray;
    height: 14px;

    &__last {
      color: $color-accent;
    }
  }

  > .drag-area {
    margin-left: 0;
    height: auto;
    transition: all 0.4s ease;
    overflow: unset;

    > .item-group {
      display: block;

      .drag-area__name {
        font-size: 14px;
        line-height: 19px;
        min-height: 26px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .filter-quick-calculation {
    padding: 20px;
    min-width: 152px;
  }
}
</style>
