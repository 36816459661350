import $api from '@/helpers/config-axios';

import authHeader from './auth-header';
import store from "@/store";
import {eventBus} from "@/main";
import {errorHandler} from "@/assets/scripts/scripts";

class QuickCalculationService {
    refactorDirectories(arr) {
        const get_els = (arr) => {
            return arr.map(el => {
                const count = el['children']['data'].length > 0
                return {
                    ...el,
                    edit: false,
                    new: false,
                    data: [],
                    children: count ? get_els(el['children']['data']) : el['children']['data']
                }
            })
        }

        return get_els(arr)
    }

    async getDirectory() {
        return await $api
            .get('directory/position-groups-tree?include=children',
                {
                    headers: authHeader()
                })
            .then(r => {
                store.commit("products/updateDirectoriesTree", this.refactorDirectories(r.data.data[0].children.data))
                eventBus.$emit('update-elements-tree')
            })
            .catch(e => {
                errorHandler(e)
            })
    }

    async getDirectoryItems() {

        let parameters = {
            page: store.state.products.paginationPage,
            include: "unit,priceList,positionGroups,alternativeFields,properties",
            search: ''
        }

        if (store.state.products.positionCollectionId.length > 0)
            parameters.search = `position_groups:${store.state.products.positionCollectionId.join(',')}`

        return await $api
            .get('directory/items',
                {
                    params: parameters,
                    headers: authHeader()
                })
            .then(r => {
                store.commit("products/updateElements", r.data)
                store.commit("products/updatePagination", r.data)
            })
            .catch(e => {
                errorHandler(e)
            })
    }

    async getDirectorySchema(type = '', position = '', id = null) {
        let newParams = {
            include: "unit,priceList,positionGroups,alternativeFields,properties",
        }
        if (type) newParams['type'] = type
        if (position) newParams['position_group_id'] = position
        if (id) newParams['id'] = id
        return await $api
            .get('directory/item/schema',
                {
                    params: newParams,
                    headers: authHeader()
                })
    }

    async createDirectoryItems(data) {
        return await $api
            .post('directory/items', data,
                {
                    headers: authHeader()
                })
    }

    async updateDirectoryItems(id, data) {
        return await $api
            .patch('directory/items/' + id, data,
                {
                    headers: authHeader()
                })
    }

    async deleteDirectoryItems() {
        return await $api
            .delete('directory/items',
                {
                    headers: authHeader(),
                    data: {
                        ids: store.state.products.selectedItemsForDelete,
                    },
                })
    }

    async getGroup(id) {
        let parameters = {
            include: "properties,properties.property,properties.params.properties_name_mask"
        }

        return await $api
            .get('directory/position-groups/' + id,
                {
                    params: parameters,
                    headers: authHeader()
                })
    }

    async createGroup(data) {
        return await $api
            .post('directory/position-groups', data,
                {
                    headers: authHeader()
                })
            .catch(e => {
                errorHandler(e)
            })
    }

    async updateGroup(id, data) {
        return await $api
            .patch('directory/position-groups/' + id, data,
                {
                    headers: authHeader()
                })
    }

    async deleteGroups(id) {
        return await $api
            .delete('directory/position-groups/' + id,
                {
                    headers: authHeader()
                })
    }

    async rebuildTree(body) {
        return await $api
            .post('directory/rebuild-tree-position-groups', body,
                {
                    headers: authHeader()
                })
            .catch(e => {
                errorHandler(e)
            })
    }
}

export default new QuickCalculationService();
