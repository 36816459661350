import { render, staticRenderFns } from "./SidebarCenter.vue?vue&type=template&id=19a28487&scoped=true"
import script from "./SidebarCenter.vue?vue&type=script&lang=js"
export * from "./SidebarCenter.vue?vue&type=script&lang=js"
import style0 from "./SidebarCenter.vue?vue&type=style&index=0&id=19a28487&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a28487",
  null
  
)

export default component.exports