<template>
  <div :style="`max-width: ${maxWidth}px;`" class="sidebar-center">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: String,
      default: '600'
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.sidebar-center {
  flex-grow: 1;
  padding: 24px;
  height: 100vh;
  background: $color-titan-white;
  box-shadow: 2px 0 8px rgba($color: $color-gray, $alpha: 0.12);
  overflow-x: auto;
}
</style>