<template>
    <label>
        <slot/>
    </label>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
label {
    font-size: 10px;
    line-height: 13px;
    color: #9BA6B2;
}
</style>